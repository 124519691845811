
import React from 'react'
import photo_data from 'src/_data/domain.json'
import { sortAlbums } from 'src/utils.js'
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from "styled-components"
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'
import { Link } from 'gatsby'


const GlobalStyle = createGlobalStyle`
.prev-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.next-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.italic{
font-style:italic;
a{text-decoration: underlined;
&:hover{font-weight: bold;}}
}
body{margin:0}
p{
  font-family: 'Cormorant Garamond', serif;
}
.slider-control-bottomcenter{
  bottom: -3rem!important;
}
.gatsby-image-wrapper{
  display: block!important;
  margin: auto;
}
.text-center{
  text-align: center;
}
.page-content p{
	font-family: 'Cormorant Garamond', serif;
	font-size: 120%;
	text-align: justify;
    margin: 1em 1.5em 1em 1.5em;
}

.page-content{
  margin-top: -8rem!important;
	position: relative;
	background-color: #fff;
	width: 90%;
	max-width: 800px;
	margin: auto;
	z-index: 2;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding: 2em 0 5em 0;
}

@media (max-width: 400px){
	.page-content p{
	  font-size: 100%;
}
}
@media (max-width: 900px) {
	.page-content p{
  	font-size: 110%;
}
}

@media (min-width: 901px) { 
  .page-content p{
	  font-size: 120%;
  }
}
.page-title-wrapper {
  color: red;
  h2{
    text-align: center;
	font-family: 'Cinzel', serif;
	font-size: 2em;
	font-weight: normal;
	color: #8d929c;
    margin: 0.5em 0 1.5em 0;
}}
.page-content .text-center{
  text-align: center;
}
.slider-slide {
  :focus {outline: none!important;}
}


.bottom-page-image-wrapper{
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	/*height: 100%;*/
}

.bottom-page-image{
	align-self: flex-end;
	width: 100%;
    background: linear-gradient(ghostwhite 10%, rgba(0, 0, 0, 0) 35%);
}

.bottom-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}

.top-page-image-wrapper{
	position: absolute;
	z-index: -5;
	top: 0;
	width: 100%;
}

.top-page-image{
	width: 100%;
    background: linear-gradient(rgba(248, 0, 0, 0) 42%, ghostwhite 73%, ghostwhite);
}

.top-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}
`


const CarouselWrapper = styled.div`
width: 100%;
  
@media screen and (min-width: 800px){
  width: 750px;
}
  margin: auto;
  margin-bottom: 3rem;
`



const SlideWrapper = styled.div`
 ${tw`mb-8`}
`

const ImageWrapper = styled.div`
  height: calc(100% - 3rem);
`
const componentName = styled(Img)`
     picture > img {
    height: auto !important;
    }
    `
const Domain = ({ location, data }) => {


  const nodes = data.allFile.nodes

  const sortedAlbums = sortAlbums(nodes, photo_data)
  const bottom = nodes.filter(n => n.relativePath.match('0011'))[0].childImageSharp.fluid
  const top = nodes.filter(n => n.relativePath.match('0010'))[0].childImageSharp.fluid
  return (
    <>
      <GlobalStyle />

      <Container>
        <Header location={location} />
        <div className='page-content'>
          <div className='page-title-wrapper centered-text'>
            <h2>Domain</h2>
          </div>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 1).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 1).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
          <p>The present <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/park' : '/en/domain/park'}>park</Link></span>, which occupies about 25 acres, includes nearly all the characteristic features of a Choulot park: a lime avenue, a most imposing ring of planes, many “bosquets” of trees with contrasting hues and canopies and carefully designed vistas through the wooded areas. There is a newly created arboretum where planting is still in progress.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 2).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 2).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>There are two small lakes, the larger one having a small island on which sits an enchanting little swan house surrounded by swamp cypresses.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 3).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 3).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>The substantial <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/potager' : '/en/domain/potager'}>walled garden</Link></span> still has its mature box hedges, which separate the squares and paths, and includes a potager and a physic garden. Other squares have been planted with fruit trees. The farm buildings which back onto the garden provide dedicated rooms for the storage of fruit and potatoes, now restored and put back into use. There is a greenhouse, which is in productive use, although its adjacent glass house is yet to be reglazed. The whole garden has a wonderful atmosphere of tranquillity combined with a subtle sense of purpose.</p>

          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 4).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 4).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
          <p> <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/prosliers' : '/en/domain/prosliers'}>Longhouse</Link></span> probably build for the vineyard manager, also dating 1670 but restored more recently.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 7).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 7).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 7).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>The pretty <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/courtyard' : '/en/domain/courtyard'}>farm courtyard</Link></span> includes a prominent pigeonnier, in which the revolving ladder still turns at the push of a finger. The other buildings include a grand grenier, stables, various barns, a workshop and a garage.
	</p>

          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 5).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 5).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 5).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>The <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/chapel' : '/en/domain/chapel'}>chapel</Link></span>, in a former 17th century dairy, charmingly decorated by the last comte de Savigny, contains the coats-of-arms of many of the local families. It was blessed in 1920, by the authority of a Papal brief.
</p>

          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 6).length > 1 ? true : true} heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 6).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 6).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p>The <span><Link to={location.pathname.match(/\/fr\//) ? '/fr/domain/chateau' : '/en/domain/chateau'}>château</Link></span>, which had been abandoned for over 20 years, has been gradually restored and is now back in use as a family house. There is a restored vineyard manager’s cottage at the far end of the walled garden which is currently let.</p>
          <p>The park is not generally open to the public but is opened from time to time by arrangement.</p>


        </div>


      </Container>
      <div className='bottom-page-image-wrapper'>
        <div className='bottom-page-image'>
          <Img fluid={bottom}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <div className='top-page-image-wrapper'>
        <div className='top-page-image'>
          <Img fluid={top}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const data = graphql`
  query domain {
          allFile(filter: {relativeDirectory: {eq: "domain"}}) {
          nodes {
          id
        childImageSharp {
          fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed
        }
          fluid(maxWidth: 1600, quality: 100) {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
        }
        relativePath
      }
    }
  }
  `

export default Domain

